import { Injectable } from '@angular/core';
import { ContentService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getFeaturingsAll, makeKeyFromCLibraryFilterData } from '@store/reducers/featuring.reducer';
import { map, Observable, switchMap, withLatestFrom } from 'rxjs';
import * as FeaturingAction from '../actions/featuring.actions';

@Injectable()
export class FeaturingEffects {
  public constructor(private actions$: Actions, private store: Store, private contentService: ContentService) {}

  public loadFeaturings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeaturingAction.loadFeaturings),
      withLatestFrom(this.store.select(getFeaturingsAll)),
      switchMap(([{ contentFilter }, featurings]) => {
        if (!contentFilter) {
          return [];
        }
        const key = makeKeyFromCLibraryFilterData(contentFilter);
        const time = new Date().getTime();

        if (featurings[key] && featurings[key] !== undefined) {
          if (time - featurings[key]?.time < 15000) {
            return new Observable(null);
          }
        }

        return this.contentService
          .getFeaturings(contentFilter)
          .pipe(map((featuring) => FeaturingAction.loadFeaturingsSuccess({ featuring, key, time })));
      }),
    ),
  );
}
