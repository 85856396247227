import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { CollectionDetailsType } from 'lingo2-models';
import { concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { BillingService, CollectionsService, MeetingsService, UserServicesService } from '../../core/services';
import * as ContentAction from '../actions/content.actions';
import { getElementByIdOrSlug } from '../models';
import {
  getMeetingsCollection,
  getPreparedBusinessPlans,
  getCollections,
  getUserServices,
} from '../reducers/content.reducer';

@Injectable()
export class ContentEffects {
  public loadMeeting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentAction.loadMeeting),
      withLatestFrom(this.store.select(getMeetingsCollection)),
      concatMap(([{ meeting_id, slug }, meetings]) => {
        const _meeting = getElementByIdOrSlug(meetings, meeting_id, slug);
        if (_meeting) {
          return [];
        }
        if (meeting_id) {
          return this.meetingService
            .getMeetingById(meeting_id)
            .pipe(map((meeting) => ContentAction.loadMeetingSuccess({ meeting })));
        }
        if (slug) {
          return this.meetingService
            .getMeetingBySlug(slug)
            .pipe(map((meeting) => ContentAction.loadMeetingSuccess({ meeting })));
        }
        // Пустой ответ
        return [];
      }),
    ),
  );

  public loadUserService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentAction.loadUserService),
      withLatestFrom(this.store.select(getUserServices)),
      switchMap(([{ user_service_id }, userServices]) => {
        const service = userServices.find((s) => s.id === user_service_id);
        if (service) {
          return [];
        }
        return this.userServices
          .getById(user_service_id)
          .pipe(map((userService) => ContentAction.loadUserServiceSuccess({ userService })));
      }),
    ),
  );

  public loadCollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentAction.loadCollection),
      withLatestFrom(this.store.select(getCollections)),
      switchMap(([{ collection_id }, collections]) => {
        const collect = collections.find((s) => s.id === collection_id);
        if (collect) {
          return [];
        }
        const details: CollectionDetailsType[] = [
          'id',
          'slug',
          'title',
          'excerpt',
          'type',
          'category',
          'language',
          'subject',
          'author:sm',
          'description',
          'multi_cover:lg:md',
          'price_tiers',
          'discount',
          'visit_info',
          'stats',
          'changed_at',
          'moderation_status',
          'moderation_message',
        ];
        return this.userCollections
          .getById(collection_id, details)
          .pipe(map((collection) => ContentAction.loadCollectionSuccess({ collection })));
      }),
    ),
  );

  public loadBillingPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentAction.loadBillingPlans),
      withLatestFrom(this.store.select(getPreparedBusinessPlans)),
      switchMap(([, storedBillingPlans]) => {
        if (storedBillingPlans.length > 0) {
          return [];
        }
        return this.billingService
          .getPlans()
          .pipe(map((billingPlans) => ContentAction.setBillingPlans({ billingPlans })));
      }),
    ),
  );

  public constructor(
    private actions$: Actions,
    private meetingService: MeetingsService,
    private userServices: UserServicesService,
    private userCollections: CollectionsService,
    private billingService: BillingService,
    private store: Store,
  ) {}
}
