import { Injectable } from '@angular/core';
import { CommentService } from '@app/core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, of, switchMap, withLatestFrom } from 'rxjs';
import * as CommentAction from '../actions/comment.actions';
import * as UserAction from '../actions/users.actions';
import { getUsers } from '../reducers/users.reducer';

@Injectable()
export class CommentEffects {
  public loadComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentAction.loadComment),
      switchMap(({ thread_id, comment_id }) =>
        this.commentService
          .getThreadComment(thread_id, comment_id)
          .pipe(map((comment) => CommentAction.loadCommentSuccess({ comment }))),
      ),
    ),
  );

  public loadThread$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentAction.loadThread),
      switchMap(({ thread_id }) =>
        this.commentService
          .getThreadComments(thread_id, {}, { page: 1, pageSize: 100 })
          .pipe(map((result) => CommentAction.loadCommentsSuccess({ comments: result.results }))),
      ),
    ),
  );

  public loadCommentAuthors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentAction.loadCommentsSuccess),
      withLatestFrom(this.store.select(getUsers)),
      switchMap(([{ comments }, userCollection]) => {
        const authors_ids = comments.map((comment) => comment.author_id).filter((id) => !userCollection[id]);
        return of(UserAction.loadUsers({ user_ids: authors_ids }));
      }),
    ),
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly commentService: CommentService,
    private readonly store: Store,
  ) {}
}
