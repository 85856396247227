import { Injectable } from '@angular/core';
import { AccountService, BillingService, ConfigService, MeetingsService, ProfileService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  getAccountCheck,
  getBillingSettings,
  getCountries,
  getLanguages,
  getNearestMeeting,
  getProfileSettings,
  getSubjects,
  getSubjectsV2,
} from '@store/reducers/data.reducer';
import { EContentOrder } from 'lingo2-models';
import { map, of, switchMap, withLatestFrom } from 'rxjs';
import * as DataActions from '../actions/data.actions';

@Injectable()
export class DataEffects {
  public loadAccountCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadAccountCheck),
      withLatestFrom(this.store.select(getAccountCheck)),
      switchMap(([, existAccountCheck]) => {
        if (Object.keys(existAccountCheck).length) {
          return of();
        }
        return this.accountService
          .accountCheck()
          .pipe(map((accountCheck) => DataActions.loadAccountCheckSuccess({ accountCheckData: accountCheck })));
      }),
    ),
  );
  public forceLoadAccountCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.forceLoadAccountCheck),
      switchMap(() =>
        this.accountService
          .accountCheck()
          .pipe(map((accountCheck) => DataActions.loadAccountCheckSuccess({ accountCheckData: accountCheck }))),
      ),
    ),
  );
  public loadCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadCountries),
      withLatestFrom(this.store.select(getCountries)),
      switchMap(([{ locale }, existCountries]) => {
        if (existCountries.length) {
          return of();
        }
        return this.configService
          .getCountries(locale)
          .pipe(map((countries) => DataActions.loadCountriesSuccess({ countries })));
      }),
    ),
  );
  public forceLoadCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.forceLoadCountries),
      switchMap(({ locale }) =>
        this.configService
          .getCountries(locale)
          .pipe(map((countries) => DataActions.loadCountriesSuccess({ countries }))),
      ),
    ),
  );
  public loadAllLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadAllLanguages),
      withLatestFrom(this.store.select(getLanguages)),
      switchMap(([{ locale }, existLanguages]) => {
        if (existLanguages.all.length) {
          return of();
        }
        return this.configService
          .getLanguages(locale, true)
          .pipe(map((languages) => DataActions.loadAllLanguagesSuccess({ languages })));
      }),
    ),
  );
  public loadFilteredLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadFilteredLanguages),
      withLatestFrom(this.store.select(getLanguages)),
      switchMap(([{ locale }, existLanguages]) => {
        if (existLanguages.filtered.length) {
          return of();
        }
        return this.configService
          .getLanguages(locale, false)
          .pipe(map((languages) => DataActions.loadFilteredLanguagesSuccess({ languages })));
      }),
    ),
  );
  public loadNearestMeeting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadMyNearestMeeting),
      withLatestFrom(this.store.select(getNearestMeeting)),
      switchMap(([, existNearestMeeting]) => {
        if (existNearestMeeting.length) {
          return of();
        }
        return this.meetingsService
          .getMeetings(
            {
              upcoming: true, // те, в которых мне предстоит заниматься
              order: EContentOrder.date_asc, // сортировка по возрастанию дат
            },
            { page: 1, pageSize: 1 },
          )
          .pipe(map((meeting) => DataActions.loadMyNearestMeetingSuccess({ meeting: meeting.results })));
      }),
    ),
  );
  public forceLoadNearestMeeting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.forceLoadMyNearestMeeting),
      switchMap(() =>
        this.meetingsService
          .getMeetings(
            {
              upcoming: true, // те, в которых мне предстоит заниматься
              order: EContentOrder.date_asc, // сортировка по возрастанию дат
            },
            { page: 1, pageSize: 1 },
          )
          .pipe(map((meeting) => DataActions.loadMyNearestMeetingSuccess({ meeting: meeting.results }))),
      ),
    ),
  );

  public loadProfileSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadProfileSettings),
      withLatestFrom(this.store.select(getProfileSettings)),
      switchMap(([, existSettings]) => {
        if (existSettings.id) {
          return of();
        }
        return this.profileService
          .getSettings()
          .pipe(map((settings) => DataActions.loadProfileSettingSuccess({ settings })));
      }),
    ),
  );

  public loadBillingSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadBillingSetting),
      withLatestFrom(this.store.select(getBillingSettings)),
      switchMap(([{ force }, existSettings]) => {
        if (existSettings && Object.keys(existSettings || {}).length && !force) {
          return of();
        }
        return this.billingService
          .getSettings$()
          .pipe(map((settings) => DataActions.loadBillingSettingSuccess({ settings })));
      }),
    ),
  );

  public loadSubjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadSubjects),
      withLatestFrom(this.store.select(getSubjects)),
      switchMap(([{ locale, force }, subjects]) => {
        if (Object.keys(subjects).length && !force) {
          return of();
        }
        return this.configService
          .getSubjects(locale)
          .pipe(map((allsubjects) => DataActions.loadSubjectSuccess({ subjects: allsubjects })));
      }),
    ),
  );

  public loadSubjectsV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadSubjectsV2),
      withLatestFrom(this.store.select(getSubjectsV2)),
      switchMap(([{ locale, force }, subjects]) => {
        if (Object.keys(subjects).length && !force) {
          return of();
        }
        return this.configService
          .getSubjectsV2(locale)
          .pipe(map((allsubjects) => DataActions.loadSubjectsV2Success({ subjectsV2: allsubjects })));
      }),
    ),
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly accountService: AccountService,
    private readonly profileService: ProfileService,
    private readonly billingService: BillingService,
    private readonly configService: ConfigService,
    private readonly meetingsService: MeetingsService,
    private readonly store: Store,
  ) {}
}
