import { Injectable } from '@angular/core';
import { PinService } from '@app/core/services/lingo2-content/pin.service';
import {
  BillingService,
  ConfigService,
  ContextService,
  FeaturesService,
  MeetingsService,
  PlatformService,
  ProfileService,
  ScheduleService,
  SingleEventExtenderService,
  UserServicesService,
} from '@core/services';
import { environment } from '@env/environment';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { addHours, endOfWeek, startOfWeek } from 'date-fns';
import {
  FeatureEnum,
  FindUserServiceStrategyEnum,
  IUserScheduleFilter,
  MeetingDetailsType,
  MeetingStatusEnum,
  SingleEvent,
} from 'lingo2-models';
import { DateFnsConfigurationService } from 'lingo2-ngx-date-fns';
import { catchError, concatMap, filter, map, of, switchMap, withLatestFrom } from 'rxjs';
import * as ProfileAction from '../actions/profile.actions';
import * as UsersAction from '../actions/users.actions';
import { getMeetingsCollection } from '../reducers/content.reducer';
import {
  getMe,
  getMeetingForReschedule,
  getMyMeetingsObject,
  getMyProfile,
  getMyScheduleCurrentDay,
} from '../reducers/profile.reducer';

const meetingDetails: MeetingDetailsType[] = [
  'id',
  'slug',
  'title',
  'cover',
  'cover_id',
  'author:md',
  'subject',
  'type',
  // 'level',
  'language',
  'begin_at',
  'end_at',
  'duration',
  'description',
  // 'keywords',
  'options',
  'reservations_count',
  'participants:sm',
  'participants_limit',
  'participants_count',
  'charging',
  'price',
  'currency_id',
  'visit_info',
  'can',
  'is',
  'has',
  'category_id',
  // 'published_at',
  'created_at',
  'status',
  'started_at',
  'finished_at',
  'user_service_id',
  'user_service',
  'user_service_contract_id',
  'user_service_contract',
  'classroom',
  'options',
];

const chatEndpoint = environment.chat_url.replace('/web/chat', '/');

@Injectable()
export class ProfileEffects {
  public loadMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileAction.loadMe),
      withLatestFrom(this.store.select(getMe)),
      switchMap(([, user]) => {
        if (user) {
          return [];
        }
        return this.contextService.me$.pipe(map((me) => ProfileAction.loadMeSuccess({ me })));
      }),
    ),
  );

  public loadMyProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileAction.loadMeSuccess),
      filter(({ me }) => !!me),
      withLatestFrom(this.store.select(getMyProfile)),
      switchMap(([{ me }, myProfile]) => {
        if (myProfile?.birth_date) {
          return [];
        }
        return this.profileService
          .getProfile(me.id)
          .pipe(concatMap((profile) => [ProfileAction.loadMyProfileSuccess({ profile })]));
      }),
    ),
  );

  public loadMyMeetings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileAction.loadMyMeetings),
      withLatestFrom(this.store.select(getMyMeetingsObject)),
      switchMap(([{ page, force, filter: _filter }, myMeetings]) => {
        if (!filter && !force && myMeetings.loaded && page <= myMeetings.pagination.page) {
          return of({ type: '[Profile Effects] Meetings already loaded.' });
        }
        let pagination = myMeetings.pagination;
        if (_filter) {
          pagination = {
            ...myMeetings.pagination,
            pageSize: Number.MAX_SAFE_INTEGER,
            page: 1,
          };
        }
        return this.meetingsService
          .getMeetings({ ...myMeetings.filter, ..._filter }, pagination, meetingDetails)
          .pipe(map((meetingResponse) => ProfileAction.loadMyMeetingsSuccess({ meetingResponse })));
      }),
    ),
  );

  public updateMyGameStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileAction.updateMyGameStats),
      withLatestFrom(this.store.select(getMe)),
      concatMap(([, me]) => of(UsersAction.updateGameStats({ user_id: me.id }))),
    ),
  );

  // Services
  public loadMyServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileAction.loadMyServices),
      withLatestFrom(this.store.select(getMe)),
      switchMap(([, me]) => {
        if (!me) {
          return [];
        }
        return this.userServicesService
          .getServices(
            {
              use: FindUserServiceStrategyEnum.owned,
              author_id: me.id,
            },
            {
              page: 1,
              pageSize: 50,
            },
          )
          .pipe(map((services) => ProfileAction.loadMyServicesSuccess({ services })));
      }),
    ),
  );

  // Scheduling
  public loadMySchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileAction.loadMySchedule),
      withLatestFrom(this.store.select(getMyScheduleCurrentDay)),
      switchMap(([{ date, school_id, account_id }, currentDay]) => {
        if (!date) {
          date = currentDay || new Date();
        }

        const _filter: Partial<IUserScheduleFilter> = {
          date_from: startOfWeek(date, { locale: this.dateConfig.locale() }),
          date_to: endOfWeek(date, { locale: this.dateConfig.locale() }),
          school_id: [school_id],
          account_id,
        };

        return this.scheduleService.getSchedule(_filter).pipe(
          switchMap((events) => this.eventExtenderService.extend$(events, ['meeting', 'user_service'])),
          map((events: SingleEvent[]) => ProfileAction.loadMyScheduleSuccess({ events })),
        );
      }),
    ),
  );

  public markHours$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileAction.markHours),
      switchMap(({ status, start, end }) => {
        if (!end) {
          end = addHours(start, 1);
        }
        return this.scheduleService.markHours(status, start, end).pipe(map(() => ProfileAction.markHoursSuccess()));
      }),
    ),
  );

  public updateMySchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileAction.markHoursSuccess),
      withLatestFrom(this.store.select(getMyScheduleCurrentDay)),
      switchMap(([, date]) => {
        if (!date) {
          date = new Date();
        }
        const _filter: Partial<IUserScheduleFilter> = {
          date_from: startOfWeek(date, { locale: this.dateConfig.locale() }),
          date_to: endOfWeek(date, { locale: this.dateConfig.locale() }),
        };
        return this.scheduleService
          .getSchedule(_filter)
          .pipe(map((events) => ProfileAction.loadMyScheduleSuccess({ events })));
      }),
    ),
  );

  /** @todo Move to meetings.effects */
  public acceptMeeting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileAction.acceptMeeting),
      withLatestFrom(this.store.select(getMeetingsCollection)),
      switchMap(([{ meeting_id }, meetingsCollection]) => {
        let service = this.meetingsService.acceptMeeting(meeting_id);
        if (meetingsCollection[meeting_id].status === MeetingStatusEnum.reschedule_required) {
          service = this.meetingsService.acceptMeetingReschedule(meeting_id);
        }
        return service.pipe(
          map(() => ProfileAction.acceptMeetingSuccess({ meeting_id })),
          catchError(() => of(ProfileAction.acceptMeetingFail())),
        );
      }),
    ),
  );

  public declineMeeting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileAction.declineMeeting),
      withLatestFrom(this.store.select(getMeetingsCollection)),
      switchMap(([{ meeting_id }, meetingsCollection]) => {
        let service = this.meetingsService.declineMeeting(meeting_id);
        if (meetingsCollection[meeting_id].status === MeetingStatusEnum.reschedule_required) {
          service = this.meetingsService.declineMeetingReschedule(meeting_id);
        }
        return service.pipe(
          map(() => ProfileAction.declineMeetingSuccess()),
          catchError(() => of(ProfileAction.acceptMeetingFail())),
        );
      }),
    ),
  );

  public forceReloadMyMeetings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileAction.acceptMeetingSuccess, ProfileAction.declineMeetingSuccess),
      switchMap(() => of(ProfileAction.loadMyMeetings({ page: 1, force: true }))),
    ),
  );

  public meetingChangeRequestFinished$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProfileAction.requestMeetingRescheduleSuccess,
        ProfileAction.acceptMeetingRescheduleSuccess,
        ProfileAction.declineMeetingRescheduleSuccess,
      ),
      switchMap(({ data }) => {
        if (data.result || data.allow) {
          return [ProfileAction.loadMyMeetings({ page: 1, force: true }), ProfileAction.loadMySchedule({})];
        }
        return of({ type: '[Profile Effects] Changing meeting has false result, dont need to update events' });
      }),
    ),
  );

  public loadMyBillingPlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileAction.loadMyBillingPlan),
      switchMap(() =>
        this.billingService
          .getActivePlan()
          .pipe(map((myBillingPlan) => ProfileAction.setMyBillingPlan({ myBillingPlan }))),
      ),
    ),
  );

  /** reschedule */
  public rescheduleMeetingRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileAction.requestMeetingReschedule),
      withLatestFrom(this.store.select(getMeetingForReschedule)),
      switchMap(([{ begin_at }, meeting]) =>
        this.meetingsService
          .requestMeetingReschedule(meeting.id, begin_at)
          .pipe(map((data) => ProfileAction.requestMeetingRescheduleSuccess({ meeting_id: meeting.id, data }))),
      ),
    ),
  );

  public rescheduleMeetingAccept$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileAction.acceptMeetingReschedule),
      switchMap(({ meeting_id }) =>
        this.meetingsService
          .acceptMeetingReschedule(meeting_id)
          .pipe(map((data) => ProfileAction.acceptMeetingRescheduleSuccess({ meeting_id, data }))),
      ),
    ),
  );

  public rescheduleMeetingDecline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileAction.rejectMeetingReschedule),
      switchMap(({ meeting_id }) =>
        this.meetingsService
          .declineMeetingReschedule(meeting_id)
          .pipe(map((data) => ProfileAction.declineMeetingRescheduleSuccess({ meeting_id, data }))),
      ),
    ),
  );

  public rescheduleMeetingWithdraw$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileAction.withdrawMeetingReschedule),
      switchMap(({ meeting_id }) =>
        this.meetingsService
          .withdrawMeetingReschedule(meeting_id)
          .pipe(map((data) => ProfileAction.withdrawMeetingRescheduleSuccess({ meeting_id, data }))),
      ),
    ),
  );

  /** Pins */
  public loadPins$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileAction.loadPins),
      switchMap(() => this.pinsService.getPinnedList().pipe(map((pins) => ProfileAction.loadPinsSuccess({ pins })))),
    ),
  );

  public createPin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileAction.createPin),
      switchMap(({ pin }) => this.pinsService.addPin(pin).pipe(map(() => ProfileAction.loadPins()))),
    ),
  );

  public removePin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileAction.removePin),
      switchMap(({ pin_id }) => this.pinsService.deletePin(pin_id).pipe(map(() => ProfileAction.loadPins()))),
    ),
  );

  public constructor(
    private features: FeaturesService,
    private platform: PlatformService,
    private actions$: Actions,
    private readonly contextService: ContextService,
    private readonly meetingsService: MeetingsService,
    private readonly scheduleService: ScheduleService,
    private readonly userServicesService: UserServicesService,
    private readonly profileService: ProfileService,
    private readonly configService: ConfigService,
    private readonly pinsService: PinService,
    private readonly store: Store,
    private readonly billingService: BillingService,
    private readonly eventExtenderService: SingleEventExtenderService,
    private readonly dateConfig: DateFnsConfigurationService,
  ) {}
}
