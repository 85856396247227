import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { PinnedEntity } from 'lingo2-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PinService {
  constructor(private http: HttpClient) {}

  /**
   * Список закреплённых учебных классов
   * */
  public getPinnedList(): Observable<PinnedEntity[]> {
    const url = `${environment.content_url}/pin/`;
    return this.http.get<PinnedEntity[]>(url, { observe: 'response' }).pipe(map((response) => response.body));
  }

  /**
   * Добавить класс в закреплённых
   */
  public addPin(body: Partial<PinnedEntity>): Observable<Partial<PinnedEntity>> {
    const url = `${environment.content_url}/pin/`;
    return this.http.post<PinnedEntity>(url, body, { observe: 'response' }).pipe(map(this.handlePinResponse));
  }

  /**
   * Удалить класс из закреплённых
   */
  public deletePin(id: string): Observable<Partial<PinnedEntity>> {
    const url = `${environment.content_url}/pin/${id}`;
    return this.http.delete<PinnedEntity>(url, { observe: 'response' }).pipe(map(this.handlePinResponse));
  }

  private handlePinResponse(response: HttpResponse<PinnedEntity>) {
    return response.body;
  }
}
